export const environment = {
        // serverUrl: 'http://192.168.1.110:5534/',
          //serverUrl: 'https://localhost:7284/',
         //serverUrl: 'http://182.73.21.19:37396/',
          //serverUrl: 'https://slm.e2eresearch.com/slm_api/',
         // serverUrl: 'https://slmv3.e2eresearch.com/slm_api/',
        // serverUrl: 'https://slm.unimrkt.com/slm_api/',
        serverUrl: 'https://surveys.ovationworldpanel.com/slm_api/',
       production: false,
};
